<template>
  <div id="nav">
    <div class="pc">
      <div class="logo">
        <img src="../assets/logo.jpg" alt="" />
        <div>
          <p>四川思可优人力资源有限公司</p>
          <p>SiChuan SeekYou Human Resources Co., Ltd</p>
        </div>
      </div>
      <div :class="{ list: navShow }">
        <ul>
          <li>
            <router-link to="/home" class="active">首页</router-link>
          </li>
          <li>
            <router-link to="/business">核心业务</router-link>
          </li>
          <li>
            <router-link to="/dynamic">思可优动态</router-link>
          </li>
          <li>
            <router-link to="/recruit">招纳贤士</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile">
      <i :class="navBtnClass" @click="navbtn"></i>
      <p>思可优官网</p>
      <div class="list" :style="{ display: navShow ? 'none' : 'block' }">
        <ul>
          <li v-for="(item, index) in route" :key="index" @click="go(item)">
            {{ item.name }}
          </li>
          <!-- <li>
            <router-link to="/business">核心业务</router-link>
          </li>
          <li>
            <router-link to="/dynamic">可优动态</router-link>
          </li>
          <li>
            <router-link to="/recruit">招纳贤士</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navBtnClass: "el-icon-s-unfold",
      navShow: true,
      route: [],
    };
  },
  methods: {
    navbtn() {
      this.navBtnClass = "el-icon-close";
      this.navShow = !this.navShow;
      this.navShow
        ? (this.navBtnClass = "el-icon-s-unfold")
        : (this.navBtnClass = "el-icon-close");
    },
    go(item) {
      this.$router.push(item.path);
      this.navShow = true;
      this.navShow
        ? (this.navBtnClass = "el-icon-s-unfold")
        : (this.navBtnClass = "el-icon-close");
    },
  },
  created() {
    this.route = this.$router.options.routes.slice(
      1,
      this.$router.options.routes.length
    );
  },
};
</script>

<style lang="less" scoped>
#nav {
  .mobile {
    display: none;
  }

  .pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    height: 94px;
    padding: 0 10rem;
    background-color: rgba(57, 139, 251, 1);
    color: white;
    .logo {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      p:nth-of-type(1) {
        letter-spacing: 4px;
        text-align: center;
      }

      p:nth-of-type(2) {
        font-size: 12px;
      }
    }

    .list {
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        li {
          padding: 0 3rem;
          letter-spacing: 2px;
          font-weight: 700;
          cursor: pointer;

          a {
            color: white;
            text-decoration: none;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #nav {
    .pc {
      display: none;
    }
    .mobile {
      height: 56px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      position: relative;

      i {
        font-size: 14px;
        position: absolute;
        left: 20px;
      }
      .list {
        width: 150px;
        position: absolute;
        top: 56px;
        left: 0;
        background-color: white;
        ul {
          list-style: none;
          li {
            padding: 5px 0;
            font-size: 14px;
            a {
              color: rgba(128, 128, 128, 1);
              text-decoration: none;
              font-weight: bold;
            }
            .router-link-exact-active {
              color: rgba(57, 139, 251, 1);
            }
          }
        }
      }
    }
  }
}
</style>
