import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // 首页
  {
    path: "/home",
    name: "首页",
    component: () => import("@/views/home/index.vue"),
  },
  // 核心业务
  {
    path: "/business",
    name: "核心业务",
    component: () => import("@/views/business/index.vue"),
  },
  // 优可动态
  {
    path: "/dynamic",
    name: "可优动态",
    component: () => import("@/views/dynamic/index.vue"),
  },
  //招纳贤士
  {
    path: "/recruit",
    name: "招纳贤士",
    component: () => import("@/views/recruit/index.vue"),
  },
  // 联系我们
  {
    path: "/contact",
    name: "联系我们",
    component: () => import("@/views/contact/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
