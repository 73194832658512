<template>
  <div id="app">
    <div><Nav></Nav></div>
    <div class="container"><router-view /></div>
    <div class="footer"><Footer></Footer></div>
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";

export default {
  components: { Nav, Footer },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  padding: 0;
  margin: 0;
}

.footer {
  display: flex;
  margin-top: -1px;
}

#recruit .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#dynamic .info_content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
