import store from "../store/index";
// import router from "../router/index";

export default {
  curPage(showDom) {
    store.commit("modifyData", {
      name: "showDom",
      newdata: showDom,
    });
  },
  nowPage(item) {
    store.commit("modifyData", {
      name: "nowPath",
      newdata: item,
    });
  },
  deletePage(showDom) {
    store.commit("modifyData", {
      name: "showDom",
      newdata: showDom,
    });

    console.log(store.state.showDom);
  },
};
