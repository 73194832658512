import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import single from "../src/utils/single";

import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

import "./config/rem"; //px转rem

Vue.use(ElementUI);
Vue.prototype.$single = single;


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");